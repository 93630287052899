import React from "react"
import Layout from '../../components/layout-en'
import Head from '../../components/head'
import { Link } from 'gatsby'
import { /*StaticQuery,*/ graphql } from "gatsby"
import '../../styles/blog.css'

export const query = graphql`
  query IndexQuery {

    allArticlesQuery : allStrapiArticles(sort: {fields: published_date, order: DESC}, filter: {draft: {eq: false}, article_category: {category_name: {ne: null}}}) {
      edges {
        node {
          id
          slug
          title
          cover {
            id
            url
          }
          article_category {
            category_name
          }
          category {
            category_name
          }
          published_date(formatString: "DD MMMM YYYY")
        }
      }
    }

    allHeadlineArticles : allStrapiArticles(sort: {fields: published_date, order: DESC}, filter: {draft: {}}, limit: 5) {
      edges {
        node {
          id
          slug
          title
          cover {
            id
            url
          }
          article_category {
            category_name
          }
          category {
            category_name
          }
          published_date(formatString: "DD MMMM YYYY")
        }
      }
    }

    allInfoTania : allStrapiArticles(sort: {fields: published_date, order: DESC}, filter: {draft: {}, article_category: {category_name: {eq: "InfoTania"}}, category: {category_name: {ne: null}}}, limit: 3) {
      edges {
        node {
          id
          slug
          title
          cover {
            id
            url
          }
          article_category {
            category_name
          }
          category {
            category_name
          }
          published_date(formatString: "DD MMMM YYYY")
        }
      }
    }

    allPressRelease : allStrapiArticles(sort: {fields: published_date, order: DESC}, filter: {draft: {}, article_category: {category_name: {eq: "Press Release"}}, category: {category_name: {ne: null}}}, limit: 3) {
      edges {
        node {
          id
          slug
          title
          cover {
            id
            url
          }
          category {
            category_name
          }
          article_category {
            category_name
          }
          published_date(formatString: "DD MMMM YYYY")
        }
      }
    }

    allNews : allStrapiArticles(sort: {fields: published_date, order: DESC}, filter: {draft: {}, article_category: {category_name: {eq: "News"}}, category: {category_name: {ne: null}}}, limit: 3) {
      edges {
        node {
          id
          slug
          title
          cover {
            id
            url
          }
          category {
            category_name
          }
          article_category {
            category_name
          }
          published_date(formatString: "DD MMMM YYYY")
        }
      }
    }

    allEvents : allStrapiArticles(sort: {fields: published_date, order: DESC}, filter: {draft: {}, article_category: {category_name: {eq: "Event"}}, category: {category_name: {ne: null}}}, limit: 3) {
      edges {
        node {
          id
          slug
          title
          cover {
            id
            url
          }
          category {
            category_name
          }
          article_category {
            category_name
          }
          published_date(formatString: "DD MMMM YYYY")
        }
      }
    }
  }
`



class Home extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      articleCategory : [],
      allArticles : [],
      searchArticle : ""
    };
  }

  componentDidMount(){
    this.setState({allArticles : this.props.data.allArticlesQuery.edges})

    const tagArray = [];
    for (let i = 0; i < this.props.data.allArticlesQuery.edges.length; i++){
      console.log(this.props.data.allArticlesQuery.edges[i].node)
      if (tagArray.indexOf(this.props.data.allArticlesQuery.edges[i].node.article_category.category_name) == -1){
        tagArray.push(this.props.data.allArticlesQuery.edges[i].node.article_category.category_name);
      }
    }  

    this.setState({articleCategory : tagArray})
  }

  handleInput = (e) => {
    this.setState({searchArticle : e.target.value});
  }
  
  

  render(){

    const headlineArticles = this.state.allArticles.slice(0, 5);
    const leftArticles = headlineArticles.slice(0, 1);
    const rightArticles = headlineArticles.slice(1, 5);

    let filteredArticles = this.state.allArticles.filter((article) => {
      return article.node.title.toLowerCase().includes(this.state.searchArticle.toLowerCase());
    });

    return(
        <Layout>
          <Head title = "Blog"/>
          <div className = "blog-page-container">
            <div className = "blog-page-header">
              <div className = "blog-page-title">What's On Neurafarm</div>
              <div className = "blog-page-subTitle">Our stories, latest updates, and insights. Find anything you want to know about us.</div>
              <div className = "blog-search-container">
                <form>
                        
                  <input 
                    type = "text" 
                    name = "keyword"
                    onChange = {this.handleInput}
                    placeholder = "Search blog posts..."
                    id = "form-search"/>
                
                </form>
                

              </div>
            </div>
            
            <div
              style = {{display : this.state.searchArticle !== "" ? "none" : "inline-block"}} 
              className = "blog-card-body">
              <ul className = "blog-card-container">
                <div id = "left-headline-card-container">
                  {leftArticles.map((article, i) => (
                      <li key = {article.node.id}  className = "left-headline-card">
                        
                          <Link to = {`/blog/${article.node.article_category.category_name}/${article.node.category.category_name}/${article.node.slug}`} className = "blog-card-link">

                            {article.node.cover.map(cover_data => (
                                <img key = {cover_data.id} src = {`${cover_data.url}`} alt = "article-image" className = "blog-card-image" width="100%"/>
                            ))}

                            <div className = "blog-card-title">{article.node.title}</div>

                            <div className = "blog-card-info">

                              <div className = "blog-card-tag">{article.node.category.category_name}</div>
                              
                              <div className = "blog-card-create-date">{article.node.published_date}</div>
                              
                            </div>

                          </Link>
                      </li>
                  ))}
                </div>
                <div id = "right-headline-card-container">
                  {rightArticles.map((article, i) => (
                      <li key = {article.node.id}  className = "right-headline-card">
                        
                          <Link to = {`/blog/${article.node.article_category.category_name}/${article.node.category.category_name}/${article.node.slug}`} className = "blog-card-link">

                            {article.node.cover.map(cover_data => (
                                <img key = {cover_data.id} src = {`${cover_data.url}`} alt = "article-image" className = "blog-card-image" width="100%"/>
                            ))}

                            <div className = "blog-card-title">{article.node.title}</div>

                            <div className = "blog-card-info">

                              <div className = "blog-card-tag">{article.node.category.category_name}</div>
                              
                              <div className = "blog-card-create-date">{article.node.published_date}</div>
                              
                            </div>

                          </Link>
                      </li>
                  ))}
                </div>
              </ul>

              <div className = "blog-section-divider" />

              <div className = "blog-section-header">
                <Link to = "/blog/InfoTania" className = "blog-section-title">InfoTania</Link>
                <div className = "blog-section-subTitle">Agriculture info, tips & trick and trivia. Learn about agriculture in a fun way</div>
              </div>

              <ul className = "blog-card-container">
                  {this.props.data.allInfoTania.edges.map(document => (
                      <li key = {document.node.id}  className = "blog-card">
                        
                          <Link to = {`/blog/${document.node.article_category.category_name}/${document.node.category.category_name}/${document.node.slug}`} className = "blog-card-link">

                            {document.node.cover.map(cover_data => (
                                <img key = {cover_data.id} src = {`${cover_data.url}`} alt = "article-image" className = "blog-card-image" width="100%"/>
                            ))}

                            <div className = "blog-card-title">{document.node.title}</div>

                            <div className = "blog-card-info">

                              <div className = "blog-card-tag">{document.node.category.category_name}</div>
                              
                              <div className = "blog-card-create-date">{document.node.published_date}</div>
                              
                            </div>

                          </Link>
                      </li>
                  ))}
              </ul>

              {/* <div className = "blog-section-divider" />

              <div className = "blog-section-header">
                <Link to = "/blog/Press Release" className = "blog-section-title">Press Release</Link>
                <div className = "blog-section-subTitle">Neurafarm�s latest activity</div>
              </div>

               <ul className = "blog-card-container">
                  {this.props.data.allPressRelease.edges.map(document => (
                      <li key = {document.node.id}  className = "blog-card">
                        
                          <Link to = {`/blog/${document.node.article_category.category_name}/${document.node.category.category_name}/${document.node.slug}`} className = "blog-card-link">

                            {document.node.cover.map(cover_data => (
                                <img key = {cover_data.id} src = {`${cover_data.url}`} alt = "article-image" className = "blog-card-image" width="100%"/>
                            ))}

                            <div className = "blog-card-title">{document.node.title}</div>

                            <div className = "blog-card-info">

                              <div className = "blog-card-tag">{document.node.category.category_name}</div>
                              
                              <div className = "blog-card-create-date">{document.node.published_date}</div>
                              
                            </div>

                          </Link>
                      </li>
                  ))}
              </ul> */}

              <div className = "blog-section-divider" />

              <div className = "blog-section-header">
                <Link to = "/blog/News" className = "blog-section-title">News</Link>
                <div className = "blog-section-subTitle">Neurafarm's latest activity</div>
              </div>

              <ul className = "blog-card-container">
                  {this.props.data.allNews.edges.map(document => (
                      <li key = {document.node.id}  className = "blog-card">
                        
                          <Link to = {`/blog/${document.node.article_category.category_name}/${document.node.category.category_name}/${document.node.slug}`} className = "blog-card-link">

                            {document.node.cover.map(cover_data => (
                                <img key = {cover_data.id} src = {`${cover_data.url}`} alt = "article-image" className = "blog-card-image" width="100%"/>
                            ))}

                            <div className = "blog-card-title">{document.node.title}</div>

                            <div className = "blog-card-info">

                              <div className = "blog-card-tag">{document.node.article_category.category_name}</div>
                              
                              <div className = "blog-card-create-date">{document.node.published_date}</div>
                              
                            </div>

                          </Link>
                      </li>
                  ))}
              </ul>

              <div className = "blog-section-divider" />

              <div className = "blog-section-header">
                <Link to = "/blog/Event" className = "blog-section-title">Events</Link>
                <div className = "blog-section-subTitle">Recap and future events by Neurafarm</div>
              </div>

              <ul className = "blog-card-container">
                  {this.props.data.allEvents.edges.map(document => (
                      <li key = {document.node.id}  className = "blog-card">
                        
                          <Link to = {`/blog/${document.node.article_category.category_name}/${document.node.category.category_name}/${document.node.slug}`} className = "blog-card-link">

                            {document.node.cover.map(cover_data => (
                                <img key = {cover_data.id} src = {`${cover_data.url}`} alt = "article-image" className = "blog-card-image" width="100%"/>
                            ))}

                            <div className = "blog-card-title">{document.node.title}</div>

                            <div className = "blog-card-info">

                              <div className = "blog-card-tag">{document.node.article_category.category_name}</div>
                              
                              <div className = "blog-card-create-date">{document.node.published_date}</div>
                              
                            </div>

                          </Link>
                      </li>
                  ))}
              </ul>
            </div>

            <div
              style = {{display : this.state.searchArticle === "" ? "none" : "inline-block"}} 
              className = "blog-card-search-result">
              <ul className = "blog-card-container">
                  {filteredArticles.map(document => (
                      <li key = {document.node.id}  className = "blog-card">
                        
                          <Link to = {`/blog/${document.node.article_category.category_name}/${document.node.category.category_name}/${document.node.slug}`} className = "blog-card-link">

                            {document.node.cover.map(cover_data => (
                                <img key = {cover_data.id} src = {`${cover_data.url}`} alt = "article-image" className = "blog-card-image" width="100%"/>
                            ))}

                            <div className = "blog-card-title">{document.node.title}</div>

                            <div className = "blog-card-info">

                              <div className = "blog-card-tag">{document.node.article_category.category_name}</div>
                              
                              <div className = "blog-card-create-date">{document.node.published_date}</div>
                              
                            </div>

                          </Link>
                      </li>
                  ))}
              </ul>
            </div>


            <div className = "blog-page-footer">
              <div className = "blog-page-footer-title">Browse by Category</div>
              <div className = "blog-page-footer-tag-container">
                {this.state.articleCategory.map((tag, i) => (
                  <Link key = {i} to = {`/blog/${tag}`} className = "blog-page-footer-tag">{tag}</Link>
                ))}
              </div>
              
            </div>
          </div>
        </Layout>
    ) 
  }
}

export default Home;

